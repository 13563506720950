import {
    Abstract
} from './Abstract';

export class PriceList extends Abstract {
    constructor(session) {
        super('comercial/PriceList', session);
        this.name = null;
        this.key_name = null;
    }
}